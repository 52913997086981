import * as React from "react"
import * as moment from 'moment'

import {
    List, Datagrid, Show, Edit, TextField,
    FunctionField, EditButton, ShowButton, TextInput, SelectInput,
    DateInput, DateTimeInput, TabbedForm, FormTab,
    FormDataConsumer
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { Form } from 'react-final-form'
import { Box, Button, InputAdornment } from '@material-ui/core'
import BookIcon from '@material-ui/icons/Book'
import ContentFilter from "@material-ui/icons/FilterList"
import SearchIcon from '@material-ui/icons/Search'

import * as config from './config'

export const EMailIcon = BookIcon

const PostFilterForm = ({
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
    open
}) => {
    if (!displayedFilters.main) return null;

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mt={8} />
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={2}>
                                <TextInput
                                    resettable
                                    helperText={false}
                                    source="query"
                                    label="Search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box component="span" mr={2}>
                                <SelectInput source="status" choices={[
                                    { id: 'manual', name: 'Potrebna ručna obrada' },
                                    { id: '*', name: 'Svi mailovi' }
                                ]} helperText={false} />
                            </Box>
                            <Box component="span" mr={2} mb={1.5}>
                                <Button variant="outlined" color="primary" type="submit">
                                    Filter
                                </Button>
                            </Box>
                            <Box component="span" mb={1.5}>
                                <Button variant="outlined" onClick={resetFilter}>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={1}>
                                <DateInput source="datetime_start" helperText={false} />
                            </Box>
                            <Box component="span" mr={1}>
                                <DateInput source="datetime_end" helperText={false} />
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div >
    );
};

const GridButtons = ({ id, record, resource }) => (
    record.status === 'manual' ?
        <EditButton basePath="/emails" record={record} />
        :
        <ShowButton basePath="/emails" record={record} />
);

const PostFilterButton = ({ showFilter }) => (
    <Button
        size="small"
        color="primary"
        onClick={() => showFilter("main")}
        startIcon={<ContentFilter />}
    >
        Filter
    </Button>
);

const PostFilter = props => {
    return props.context === "button" ?
        <PostFilterButton {...props} />
        :
        <PostFilterForm {...props} />

}

export const EMailList = (props) => (
    <List {...props} perPage={10} filterDefaultValues={{ status: 'manual' }} title="Mail arhiva" sort={{ field: 'datetime', order: 'DESC' }} showFilter={["status"]} displayedFilters={["status"]} filters={<PostFilter />}>
        <Datagrid isRowSelectable={$ => false}>
            <FunctionField label="Status" source="status" render={record => {
                return record.status_reason
            }} />
            <FunctionField label="Vrijeme" source="datetime" render={record => {
                return moment(record.datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
            }} />
            {/* <TextField label="Vrijeme" source="datetime" /> */}
            <TextField label="Pošiljatelj" source="sender" />
            <TextField label="Naslov" source="subject" />
            <TextField label="AWB" source="awb" />

            <GridButtons />
        </Datagrid>
    </List>
)

const EMailTitle = ({ id, record, resource }) => {
    return <span id="id">Mail {record ? `"${record.subject}"` : ''}</span>;
}

const EMailAttachments = ({ id, record, resource }) => {
    if (record && record.attachments)
        return record.attachments.map(attachment => (
            <div key={attachment.id}><a rel="noopener noreferrer" target="_blank" href={`${config.API_URL}/emails/${record.id}/attachment/${attachment.id}/${localStorage.token}`}>{attachment.filename}</a> => {attachment.gia_filename}</div >
        ))

    return (<div></div>)
}

export const EMailEdit = (props) => (
    <Edit title={<EMailTitle />} {...props}>
        <TabbedForm>
            <FormTab label="EMail">
                <DateTimeInput source="datetime" label="Vrijeme" />

                <TextInput source="subject" label="Naslov" />

                <RichTextInput source="body" configureQuill={quill => quill.enable(false)} label="Sadržaj" toolbar={false} />

                <EMailAttachments />

                <br></br>

                <TextInput source="awb" label="AWB" />
            </FormTab>
            <FormTab label="Status">
                <TextInput source="status" label="Status" disabled />

                <RichTextInput source="upload_log" configureQuill={quill => quill.enable(false)} label="Upload log" toolbar={false} />
            </FormTab>
        </TabbedForm>
    </Edit>
)

export const EMailShow = (props) => (
    <Show title={<EMailTitle />} actions={<div></div>} {...props}>
        <TabbedForm toolbar={<div></div>}>
            <FormTab label="EMail">
                <DateTimeInput disabled source="datetime" label="Vrijeme" />

                <TextInput disabled source="subject" label="Naslov" />

                <RichTextInput source="body" configureQuill={quill => quill.enable(false)} label="Sadržaj" toolbar={false} />

                <EMailAttachments />

                <br></br>

                <TextInput disabled source="awb" label="AWB" d />
            </FormTab>
            <FormTab label="Status">
                <TextInput source="status" label="Status" disabled />

                <h3>Upload log</h3>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <pre>{formData.upload_log}</pre>
                    }
                </FormDataConsumer>
            </FormTab>
        </TabbedForm>
    </Show>
)

