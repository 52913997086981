import React from 'react';
import './App.css';

import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import { EMailShow, EMailEdit, EMailList, EMailIcon } from './emails.js'
import { InvoiceShow, InvoiceIcon, InvoiceList } from './invoices';

import * as config from './config'

const fetchJson = (url, options = {}) => {
	if (!options.headers)
		options.headers = new Headers();

	options.headers.set('Authorization', 'Bearer ' + localStorage.token);
	return fetchUtils.fetchJson(url, options);
}

const authProvider = {
	login: async params => {
		let result = await fetch(`${config.API_URL}/auth/login`, { method: 'POST', headers: { 'Content-type': 'application/json' }, body: JSON.stringify(params) })
		result = await result.json()

		if (result.result) {
			localStorage.token = result.result
			return Promise.resolve()
		}

		return Promise.reject()
	},
	logout: async params => {
		localStorage.token = ''
		return Promise.resolve()
	},
	checkAuth: async params => {
		if (!localStorage.token)
			return Promise.reject()

		let result = await fetch(`${config.API_URL}/auth/check`, { method: 'POST', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({ token: localStorage.token }) })
		result = await result.json()
		if (result.result === true)
			return Promise.resolve()

		return Promise.reject()
	},
	checkError: async error => Promise.resolve(),
	getPermissions: async params => {
		return Promise.resolve()
	},
};

class Application extends React.Component {

	render() {
		return (
			<Admin dataProvider={simpleRestProvider(config.API_URL, fetchJson)} authProvider={authProvider} >
				<Resource name="emails" options={{ label: 'Mail arhiva' }} list={EMailList} edit={EMailEdit} show={EMailShow} icon={EMailIcon} />
				<Resource name="invoices" options={{ label: 'Računi' }} list={InvoiceList} show={InvoiceShow} icon={InvoiceIcon} />
			</Admin>
		)
	}

}

export default Application;
